<template>
  <div>
    <div class="box">
      <nav-router :routerList="useRouterList">
        <img :src="icon" alt="" />
      </nav-router>
      <div class="content">
        <div class="title">{{ baseInfo.name }}</div>
        <div class="context">
          <div v-html="baseInfo.subjoinText"></div>
        </div>
      </div>
      <!-- 隐藏音乐播放按钮 原因：不确定以后需不需要-->
      <!-- <div class="btns">
        <div v-if="showMusicButton == true">
          <img :src="audio" alt="声音" @click="handleMusic(music)" />
        </div>
      </div> -->
    </div>
    <back :pageDepth="4"></back>
  </div>
</template>

<script>
import navRouter from "@/components/navRouter";
import back from "@/components/back";
const redIcon = require('@/assets/images/dangHistory/home.png')
const blueIcon = require('@/assets/images/dangHistory/home-blue.png')
const redAudio = require('@/assets/images/photo/audio.png')
const blueAudio = require('@/assets/images/photo/audio-blue.png')
const redPauseAudio = require('@/assets/images/photo/audio-pause.png')
const bluePauseAudio = require('@/assets/images/photo/audio-pause-blue.png')
import { mapGetters } from "vuex";

export default {
  name: "CustomizeContent",
  components: {
    navRouter,
    back,
  },
  data() {
    return {
      icon: redIcon,
      baseInfo: {},
      audio: redAudio,
      // 是否显示播放按钮
      // showMusicButton: true,
      isPause: false

    };
  },
  computed: {
    ...mapGetters("skin", ["useSubject", "useRouterList"]),
  },
  created() {
    if (this.useSubject === "red") {
      this.icon = redIcon;
      this.audio = redAudio
    } else {
      this.icon = blueIcon;
      this.audio = blueAudio
    }
    // this.music = this.$route.query.music
    // if (this.music == null || this.music == undefined || this.music == "") {
    //   this.showMusicButton = false
    // } else {
    //   this.showMusicButton = true
    // }
    this.getContent();
  },
  watch: {
    useSubject(val) {
      if (val === "red") {
        this.icon = redIcon;
      } else {
        this.icon = blueIcon;
      }
    },
    isPause(val) {
      if (this.useSubject === "red") {
        this.audio = val ? redAudio : redPauseAudio
      } else {
        this.audio = val ? blueAudio : bluePauseAudio
      }
    }
  },
  methods: {
    getContent() {
      this.GET(this.API_MODULE.GET_CONTENT_BY_ID, {
        id: this.$route.query.id,
      })
        .then((res) => {
          if (res.code == 0 || res.code == 200) {
            this.baseInfo = res.data
          } else {
            this.$message(res.msg);
          }
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
    handleMusic(src) {
      this.isPause = !this.isPause
      if (src) {
        this.playOrPausedAudio(this.OSS_URL + src)
      } else {
        this.$message({ message: '请联系管理员上传音频', type: 'error' })
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/themes/_handle.scss";
.box {
  width: 14.4rem;
  height: 25.6rem;
  box-sizing: border-box;
  @include background_color("background-medium");
  .content {
    width: 12.4rem;
    height: 24rem;
    background: #ffffff;
    opacity: 1;
    border-radius: 0.2rem;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 0.6rem;
    box-sizing: border-box;
    margin: 0.6rem auto 0 auto;
    text-align: left;

    .title {
      font-size: 0.42rem;
      font-weight: bold;
      @include font_color("font-medium");
    }

    .context {
      margin-top: 0.4rem;
      font-size: 0.28rem;
      color: #555555;
      text-align: justify;
      line-height: 1;

      span,
      p,
      div {
        line-height: 3;
      }
    }
  }
  .btns {
    position: fixed;
    top: 2rem;
    right: 0.68rem;

    div {
      margin-bottom: 0.6rem;

      img {
        width: 0.8rem;
      }
    }
  }
}
</style>
