<template>
  <div class="router-nav">
    <div class="nav-left">
      <slot></slot>
      <p class="router-name">
        <span
          v-for="(item, index) in routerList"
          :key="index"
          :class="[index === 0 ? '' : 'sub-router']"
          @click="jumpTo(item.path)"
          >{{ item.name }}
          <span v-if="index !== routerList.length - 1"> > </span></span
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  inject: ['reload'],
  props: {
    routerList: {
      type: Array,
      required: false,
      default: () => {
        return [
          {
            path: "/layout/index",
            name: "首页"
          }
        ];
      }
    }
  },
  methods: {
    jumpTo(path) {
      if (!path) return false;
      if (path === '/layout/app/RED_BASE/11') {
        this.$store.commit('skin/setProvince', '');
        this.reload()
      }
      this.$router.push({
        path
      });
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/themes/_handle.scss";

.router-nav {
  width: 100%;
  height: 0.6rem;
  background-color: #ffffff;
  padding-left: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;

  .nav-left {
    display: flex;
    align-items: center;

    .router-name {
      font-size: 0.16rem;
      @include font_color("font-medium");
      margin-left: 0.1rem;

      .sub-router {
        font-size: 0.16rem;
        color: #cca766;
      }
    }
  }
}
</style>
