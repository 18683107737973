<template>
  <div class="back-group">
    <div class="btn-group">
      <div
        v-if="showMore && pageDepth >= 3"
        @click="$router.push({ path: '/layout/index' })"
      >
        <img src="@/assets/images/index/home-gray.png" alt="" />
      </div>
      <div v-if="showMore" @click="handleBack">
        <img src="@/assets/images/index/back-gray.png" alt="" />
      </div>
      <div v-if="showMore" @click="showMore = !showMore">
        <img src="@/assets/images/index/close-gray.png" alt="" />
      </div>
      <div v-if="!showMore" @click="showMore = !showMore">
        <img src="@/assets/images/index/open-gray.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "index",
  data() {
    return {
      showMore: false
    };
  },
  props: {
    pageDepth: { //页面深度 1级页面 2级页面 或三级页面
      type: Number,
      default: 1
    }
  },
  computed: {
    ...mapGetters("skin", ["useSubject", "useRouterList"])
  },
  methods: {
    handleBack() {
      // this.$router.go(-1)
      this.$router.replace({ path: this.useRouterList[this.useRouterList.length - 2].path })
    }
  },
};
</script>

<style scoped lang="scss">
.back-group {
  z-index: 3005;
  position: fixed;
  right: 0.6rem;
  bottom: 0.8rem;
  /*opacity: 0.8;*/
  .btn-group {
    div {
      margin-bottom: 0.1rem;
    }
  }
  img {
    width: 1.2rem;
  }
}
</style>
